import { Redirect } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "reactstrap";
import { env } from "../../env";

const OauthAuthenticatePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authCode = urlParams.get("code");
    const provider = urlParams.get("provider");
    (async () => {
      try {
        let response = await axios.post(
          env.REACT_APP_URL + "github/generateAuthToken",
          {
            authCode,
            provider: provider || "github",
          }
        );
        if (response.data.status === "Success") {
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, []);
  if (isLoggedIn)
    return (
      <Redirect
        to={{
          pathname: "/editor",
          state: {
            isRedirected: true,
          },
        }}
      />
    );
  return (
    <Modal isOpen={true}>
      <Spinner></Spinner>
    </Modal>
  );
};

export default OauthAuthenticatePage;
