import Login from "../pages/LandingPages/Login";
import LandingPage from "../pages/LandingPages/LandingPage";
import CreateAccount from "../pages/LandingPages/CreateAccount";
import NewUserOtpVerification from "../pages/LandingPages/NewUserOtpVerification";
import ForgetPassword from "../pages/LandingPages/ForgetPassword";
import ForgetPasswordOtpVerification from "../pages/LandingPages/ForgetPasswordOtpVerification";
import ResetPassword from "../pages/LandingPages/ResetPassword";
import OnboardingMainPage from "../pages/OnboardingPages/OnBoardingMainPage";
import ConnectorsMainPage from "../pages/ConnectorsPage/ConnectorsMainPage";
import SupportPage from "../pages/LandingPages/SupportPage";
import PipelinesMainPage from "../pages/Pipelines/PipelinesMainPage";
import TemplatesMainPage from "../pages/Templates/TemplatesMainPage";
import WorkSpaceConnectorsMainPage from "../pages/WorkSpaceConnectors/WorkSpaceConnectorsMainPage";
import WindowsMainPage from "../pages/Windows/WindowsMainPage";
import DocumentationMainPage from "../pages/Documentation/DocumentationMainPage";
import SupportMainPage from "../pages/Support/SupportMainPage";
import SettingsMainPage from "../pages/Settings/SettingsMainPage";
import CondenseEditor from "../components/editorComponents/CondenseEditor";
import OauthAuthenticatePage from "../components/editorComponents/OAuthLoadingPage.jsx";
import CondenseBuildPage from "../components/buildPageComponents/CondenseBuild.jsx";
import CondenseBuildView from "../components/buildListViewComponent/CondenseBuildView.jsx";
import DeploymentConfig from "../components/deploymentPageComponents/deploymentConfig.jsx";
import CustomTransformDeployment from "../components/deploymentPageComponents/customTransformDeployment.jsx";

//NOTE: Add pages which are displayed after auth
//NOTE: Add pages which are displayed before auth
//TODO: Add React Suspense for Loading.

const Loading = () => {
  <h1 style={{ color: "white" }}>Loading....</h1>;
};

/**
 *  @type {Array}
 *  @description This Array contains all the Public Routes.
 * */
const publicRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/createAccount",
    component: CreateAccount,
  },
  {
    path: "/newUserVerification",
    component: NewUserOtpVerification,
  },
  {
    path: "/forgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/forgetPasswordOtpVerification",
    component: ForgetPasswordOtpVerification,
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
  },
  {
    path: "/support",
    component: SupportPage,
  },
  //NOTE: This route should be at the end of all other routes.
  {
    path: "/",
    exact: true,
    component: LandingPage,
  },
];

/**
 *  @type {Array}
 *  @description This Array contains all the Private Routes.
 * */
const authProtectedRoutes = [
  {
    path: "/onBoarding",
    component: OnboardingMainPage,
  },
  {
    path: "/connectorsPlayground",
    component: ConnectorsMainPage,
  },
  { path: "/pipelines", component: PipelinesMainPage },
  { path: "/templates", component: TemplatesMainPage },
  { path: "/connectors", component: WorkSpaceConnectorsMainPage },
  { path: "/windows", component: WindowsMainPage },
  { path: "/documentation", component: DocumentationMainPage },
  { path: "/navSupport", component: SupportMainPage },
  { path: "/settings", component: SettingsMainPage },
  { path: "/editor", component: CondenseEditor },
  { path: "/oauth/authorize", component: OauthAuthenticatePage },
  { path: "/build", component: CondenseBuildPage },
  { path: "/buildList", component: CondenseBuildView },
  { path: "/deploymentConfiguration", component: DeploymentConfig },
  { path: "/customDeployment", component: CustomTransformDeployment },
];

export { authProtectedRoutes, publicRoutes };
